import { TranslateFunction } from "@hooks/useTranslations";

export function isValidPhoneNumber(value: string): boolean {
  const mobileNumberPattern = /^\+([ ()-]*[0-9]){8}([ ()-]*[0-9][ ()-]*)*$/;
  const trimmedValue = trimAndRemoveSpaces(value);

  return (
    trimmedValue.length >= 13 &&
    trimmedValue.length <= 15 &&
    mobileNumberPattern.test(trimmedValue)
  );
}

export const trimAndRemoveSpaces = (value: string) =>
  value.trim().split(" ").join("");

export const isValidPhoneNumberAsMaybeErpPerson = (
  value: string,
  isErp: boolean,
  t: TranslateFunction
) => {
  if (isErp) {
    return !value.length
      ? t("validation_error_emergency_responsible_person")
      : isValidPhoneNumber(value) || t("error_invalid_mobile_phone");
  }
  if (value.length > 0) {
    return isValidPhoneNumber(value) || t("error_invalid_mobile_phone");
  }
};
